.vjs-mobile-control-layer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  touch-action: pan-x pinch-zoom;
  cursor: pointer;
  z-index: 2;
}

.vjs-mobile-control-layer-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 1rem;
  height: 1rem;
  opacity: 0.5;
}

.vjs-paused .vjs-mobile-control-layer-play {
  display: block;
}

.vjs-ended .vjs-mobile-control-layer.vjs-mobile-control-layer__hide-on-end {
  display: none;
}
