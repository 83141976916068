.vjs-seeking .vjs-custom-spinner,
.vjs-waiting .vjs-custom-spinner {
  display: block;
}

.vjs-custom-spinner {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
