.vjs-mute-button .vjs-mute-button-icon {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  mask-image: url('./volumn-normal.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background: currentColor;
}

video[muted] ~ .vjs-control-bar > .vjs-mute-button .vjs-mute-button-icon {
  mask-image: url('./volumn-muted.svg');
}
