.vjs-back-button {
  position: absolute;
  top: -0.1rem;
  left: -0.2rem;
  width: 1.23rem;
  height: 1.23rem;
  background-image: url('../../../../public/icon/arrow-white.svg');
  background-size: 33% 33%;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
  transition: opacity 0.5s;
}

.video-js.vjs-user-inactive.vjs-playing .vjs-back-button {
  opacity: 0;
}
