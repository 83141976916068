.vjs-seek-card {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0.6rem;
  left: 50%;
  height: 0.8rem;
  min-width: 3rem;
  padding: 0.2rem;
  z-index: 990;
  transform: translateX(-50%);
  border-radius: 0.25rem;
  transition: all 0.5s;
  color: #F9F9F9;
  font-size: 0.35rem;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  touch-action: none;
}

.vjs-seek-card-time{
  opacity: 0;
  line-height: 0.4rem;
}

.vjs-seek-card-sub-time {
  color: #F9F9F9;
  opacity: 0.5;
}

.vjs-seek-card.vjs-seek-active,.vjs-seek-card.vjs-seek-speed-up {
  opacity: 1;
}

.vjs-mobile-control-speed-x2 {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: 0.6rem;
}

.vjs-seek-card.vjs-seek-speed-up .vjs-mobile-control-speed-x2 {
  opacity: 1;
}

.vjs-seek-card.vjs-seek-active .vjs-seek-card-time{
  opacity: 1;
}