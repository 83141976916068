.vjs-header {
  position: absolute;
  left: 0;
  right: 0;
  align-items: center;
  height: 1.02rem;
  display: flex;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
  transition: opacity 0.5s;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

.video-js.vjs-user-inactive.vjs-playing .vjs-header {
  opacity: 0;
}
