/* 避免 header footer 滾動 */
html {
  overscroll-behavior: none;
}

body {
  overflow-y: scroll;
}

/* 強制每次最多翻一頁 */
.swiper-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.swiper-slide {
  will-change: transform;
}

html {
  /*font-family: "cwTeXYen", "Helvetica Neue", Arial, Helvetica, PingFangSC-Regular, 微軟雅黑體, "Microsoft YaHei", 微軟正黑體, "Microsoft JhengHei", "Heiti SC", "Heiti TC", STXihei, "LiHei Pro", sans-serif;*/
  font-family: Helvetica, Arial, sans-serif;
  --main-background: #121317;
  --app-background: var(--main-background);
  --page-header-background: #121317;
  --page-header-color: var(--main-color);
  --page-footer-background: #161616;
  --page-footer-color: #AAAAAA;
  --page-footer-box-shadow: none;
  --page-footer-border-top: none;
  --main-color: white;
  background: var(--app-background);
  --cms-grid-column-gap: 0.186rem;
  --cms-grid-row-gap: 0.186rem;
  --danger-color: #ff490c;
  --theme-color: #fc413e;
  --link-color: #31b1f4;
  --button-live-zone-padding: -20px;
  --layer-fullpage-modal: 1000;

  /* common element style */
  --button-primary-background: linear-gradient(180deg, #ff8a88 0%, #fc413e 34.9%, #e32823 68.75%, #ad1b16 100%);
  --button-primary-color: #fff;
  --button-secondary-background: linear-gradient(180deg, #989898 0%, #787878 28.65%, #666666 68.23%, #5a5a5a 100%);
  --button-secondary-color: #fff;
  --button-disabled-background: linear-gradient(270deg, #c7c7c7 0%, #b0b0b0 100%);
  --button-disabled-color: #fff;
  --input-background: #ECECEC;
  --input-color: #2E2E2E;
  --input-focus-background: #ECECEC;
  --input-focus-color: #2E2E2E;
  --input-placeholder-color: #AAAAAA;

  --app-header-bar--logo-size: 0.871rem;

  --app-search-field--background: #2e2e2e;
  --app-search-field--color: #aaa;
  --app-search-field--placeholder-color: #aaaaaa;

  --page-category-sidebar-background: #2e2e2e;
  --page-category-sidebar-active-background: #fc413e;
  --page-category-sidebar-active-color: white;
  --page-category-main-background: #2e2e2e;
  --page-category-region-active-color: #fc413e;
  --page-category-tag-background: #5a5a5a;
  --page-category-tag-color: var(--main-color);
  --page-sectaglist-active-color: #fc413e;

  --view-short-promotion-hint-background: #fc413e;

  --page-view-comic--card-background: #1c1c1c;
  --page-view-comic--title-color: #e6e6e6;
  --page-view-comic--desc-color: #939393;
  --page-view-comic--tag-background: var(--button-primary-background);
  --page-view-comic--tag-color: var(--button-primary-color);
  --page-view-comic--statusbar-background: black;
  --page-view-comic--statusbar-color: #e6e6e6;

  --page-view-comic--chapter-background: transparent;
  --page-view-comic--chapter-color: #D2D2D2;
  --page-view-comic--chapter-border: 1px solid #D2D2D2;
  --page-view-comic--chapter-active-background: transparent;
  --page-view-comic--chapter-active-color: #ffffff;
  --page-view-comic--chapter-active-border: 1px solid var(--theme-color);
  --page-view-comic--footer-border-color: #2e2e2e;
  --page-view-comic--start-read-background: var(--button-primary-background);
  --page-view-comic--start-read-color: var(--button-primary-color);
  --page-view-comic--more-color: var(--component-button-search-more--color);
  --page-view-comic--chapters-border-color: #D2D2D2;
  --page-view-comic--chapters-list-title-color: white;
  --page-view-comic--chapters-list-item-background: transparent;
  --page-view-comic--chapters-list-item-color: #e6e6e6;
  --page-view-comic--chapters-list-item-border: 1px solid var(--page-view-comic--chapters-list-item-color);
  --page-view-comic--chapters-list-item-active-color: #ffffff;
  --page-view-comic--chapters-list-item-active-border: 1px solid var(--theme-color);

  --page-view-comic-chapter--header-footer-background: black;
  --page-view-comic-chapter--header-footer-color: white;
  --page-view-comic-chapter--background: black;
  --page-view-comic-chapter--color: white;

  --page-game-theme-background: linear-gradient(180deg, #ffe68b 0%, #ff8c04 100%);
  --page-game-home-icon: '/game/icon-home.svg';
  --page-game--balance-bar-button-background: linear-gradient(180deg, #FC823E 0%, #AF501B 100%);
  --page-game--select-button-background: #1c1c1c;
  --page-game--select-button-color: white;
  --page-game--select-button-active-color: #fc413e;
  --page-game--hint-text-color: #d2d2d2;
  --page-game-deposit--section-background: #1c1c1c;
  --page-game-deposit--promote-tag-background: #ff7e00;
  --page-game-deposit--recommend-tag-background: #f00;
  --page-game-transfer--border: 1px solid #787878;

  --page-business-relationship--button-background: var(--button-primary-background);
  --page-business-relationship--button-text: var(--button-primary-color);
  --page-business-relationship--item-background: #2e2e2e;
  --page-business-relationship--item-text: white;
  --page-business-relationship--item-shadow: none;
  --page-business-relationship--item-border: none;

  --page-view-video--tag-background: #2e2e2e;
  --page-view-video--tag-color: var(--theme-color);
  --page-view-video--info-color: #aaaaaa;
  --page-view-video--title-color: #ececec;
  --page-view-video--source-background: var(--button-secondary-background);
  --page-view-video--source-color: var(--button-secondary-color);
  --page-view-video--active-source-background: var(--button-primary-background);
  --page-view-video--active-source-color: var(--button-primary-color);
  --page-view-video--preview-button-background: linear-gradient(
    180deg,
    #ffab5e -0.03%,
    #ff7a35 29.76%,
    #fe7337 57.56%,
    #f63f47 95.3%
  );
  --page-view-video--preview-button-color: #2e2e2e;

  --page-profile--register-box-background: var(--button-primary-background);
  --page-profile--register-box-color: var(--button-primary-color);
  --page-profile--button-icon-color: #fc413e;
  --page-profile--button-filter: none;
  --page-profile--button-color: white;
  --page-profile--item-background: #1c1c1c;
  --page-profile--name-box-color: white;
  --page-profile--item-color: #aaaaaa;
  --page-profile--vip-header-background: linear-gradient(180deg, #cbbc9b 0%, #ac8851 100%);
  --page-profile--vip-header-color: #ffffff;
  --page-profile--wallet-box-shadow: none;
  --page-profile--info-popup-background: #000000cc;
  --page-profile--info-popup-color: #e6e6e6;
  --page-profile--button-border: none;
  --page-profile--area3-item-gap-x: 0.2rem;
  --page-profile--area3-item-gap-y: 0.2rem;

  --page-search-video-viacategory--active-background: var(--button-primary-background);
  --page-search-video-viacategory--active-color: var(--button-primary-color);
  --page-search-video-viaspecific--search-button-background: var(--button-primary-background);
  --page-search-video-viaspecific--search-button-color: var(--button-primary-color);
  --page-search-video-viaspecific--search-icon: var(--theme-color);
  --page-search--hint-text-color: #AAAAAA;

  --page-login-register--submit-color: var(--button-primary-color);
  --page-login-register--submit-background: var(--button-primary-background);
  --page-game-lobby--category-color: #FFFFFF;
  --page-game-lobby--category-active-color: #2E2E2E;
  --page-game-lobby--category-icon-color: linear-gradient(97.69deg, #F4B30C 1.98%, #F47C0C 82.38%);  
  --page-game-lobby--category-icon-active-color: linear-gradient(180deg, #FC823E 0%, #AF501B 100%);

  --page-game--has-background-color: #fff;
  --page-game--withdraw-card-bottom-background: linear-gradient(
    180deg,
    rgba(28, 28, 28, 0.51) 0%,
    rgba(45, 45, 45, 0.65) 50.25%,
    rgba(79, 79, 79, 0.91) 100%
  );

  --page-game-deposit--balance-color: #fff;

  --page-actor--color: #e6e6e6;

  --page-share-background: linear-gradient(180deg, #E9BCFF 0%, #CD69FE 32.81%, #B351FF 67.71%, #8A22DA 100%);
  --page-share-title-color: #FFFFFF;
  --page-share-text-color: #FFFFFF;
  --page-share-link-color: #FAD12B;


  /* 上方狀態列 */
  --status-bar-height: 1.33rem;

  --component-comic-box--tag-color: #939393;

  --component-video-box--border-radius: 0.2564rem;
  --component-video-box-vertical--border-radius: 0.2564rem;

  --component-navigation--active-color: var(--theme-color);

  --component-button-search-more--color: #aaa;

  --component-area-title--color: white;
  --component-area-title--sub-color: #e6e6e6;
  --component-area-title--more-color: var(--component-button-search-more--color);
  --component-area-title--dec-color: #fc413e;
  --component-area-title--decorate-left-offset: 0rem;

  --component-tabs--color: var(--main-color);
  --component-tabs--active-color: #fc413e;

  --component-area-box--button-mono-background: var(--button-secondary-background);
  --component-area-box--button-mono-color: var(--button-secondary-color);
  --component-area-box--button-colorful-background: var(--button-primary-background);
  --component-area-box--button-colorful-color: var(--button-primary-color);
  --component-area-outside-padding: 0;
  --component-area-inside-padding: 0;
  --component-area-border-radius: 0;
  --component-area-border: none;
  --component-area-background: none;
  /* area box contain swiper */
  --component-area-swiper-outside-padding: 0rem 0rem;
  --component-area-swiper-border-radius: 0rem;


  --component-modal-confirm--cancel-background: var(--button-secondary-background);
  --component-modal-confirm--cancel-color: var(--button-secondary-color);
  --component-modal-confirm--confirm-background: var(--button-primary-background);
  --component-modal-confirm--confirm-color: var(--button-primary-color);
  --component-modal-select--cancel-background: var(--button-secondary-background);
  --component-modal-select--cancel-color: var(--button-secondary-color);
  --component-modal-select--confirm-background: var(--button-primary-background);
  --component-modal-select--confirm-color: var(--button-primary-color);
  --component-modal-title-color: #2e2e2e;
  --component-modal-content-color: #787878;
  --component-modal-alert-color: #000;
  --component-modal-alert-background: rgba(255, 255, 255, 0.8);

  --component-announcement-systemitem--left-background: var(--button-secondary-background);
  --component-announcement-systemitem--left-color: var(--button-secondary-color);
  --component-announcement-systemitem--right-background: var(--button-primary-background);
  --component-announcement-systemitem--right-color: var(--button-primary-color);

  --component-header--filter-color: var(--button-primary-color);
  --component-header--filter-background: var(--button-primary-background);
  --component-header--filter-border: none;
  --component-header--search-icon: var(--theme-color);

  --component-modal-big-confirm--confirm-background: var(--button-primary-background);
  --component-modal-big-confirm--confirm-color: var(--button-primary-color);

  --component-note-box--background: #1c1c1c;
  --component-note-box--color: #aaaaaa;

  --component-short-player--preview-button-background: var(--button-primary-background);
  --component-short-player--preview-button-color: var(--button-primary-color);

  user-select: none;
}

html * {
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

/** 從這裡開始客製化 */
#root > #app {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 0.426rem;
  color: #ffffff;
  background: inherit;
  /*transition: background 0.2s ease-out;*/
}

/*!** scrollbar *!*/
/*::-webkit-scrollbar {*/
/*  height: 2px;*/
/*  width: 5px;*/
/*}*/
/*::-webkit-scrollbar-thumb {*/
/*  border-radius: 5px;*/
/*  !*box-shadow: inset 0 0 5px #ffffff;*!*/
/*  !*background: #26212a;*!*/
/*}*/
/*::-webkit-scrollbar-track {*/
/*  border-radius: 0;*/
/*  background: #1b062d;*/
/*}*/

/** scrollbar */
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
::-webkit-scrollbar-track {
  display: none;
}

.no-focus-outline:focus-visible{
  outline: none;
}

/** FIXME: workaround incorrect react-simple-pull-to-refresh initial overflow value */
.ptr__children.ptr__children {
  overflow: hidden auto;
}