.vjs-end-card {
  display: none;
}

.vjs-ended .vjs-end-card.vjs-end-card--active {
  display: flex;
}

.vjs-end-card {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);

  align-items: center;
  justify-content: center;
}

.vjs-end-card .vjs-end-card--button {
  background: var(--component-short-player--preview-button-background);
  color: var(--component-short-player--preview-button-color);
  font-size: 0.358rem;
  border-radius: 2.56rem;
  font-weight: 400;
  padding: 0.2rem 0.41rem;
}
