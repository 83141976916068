.vjs-custom-title-bar {
  position: absolute;
  top: 0rem;
  left: 0.71rem;
  right: 0.21rem;
  line-height: 1.02rem;
  font-size: 0.35rem;
  color: white;

  opacity: 1;
  transition: opacity 0.5s;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-js.vjs-user-inactive.vjs-playing .vjs-custom-title-bar {
  opacity: 0;
}
