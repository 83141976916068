html {
  --theme-color: #FB196A;
  --main-background: #2E0658;
  --page-header-background: #2E0658;
  --page-footer-background: #20043D;
  --page-footer-color: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --button-primary-background: var(--gradient-pink, linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D41459 100%));
  --button-primary-color: #FFFFFF;

  --app-search-field--background: #ECECEC;
  --app-search-field--color: #2E2E2E;
  --page-search-video-viaspecific--search-icon: transparent;

  --button-secondary-background: var(--gradient-gray-01, linear-gradient(180deg, #989898 0%, #787878 28.65%, #666 68.23%, #5A5A5A 100%));

  --page-category-sidebar-background: #20043D;
  --page-category-main-background: #20043D;
  --page-category-sidebar-active-background: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --page-category-region-active-color: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --page-sectaglist-active-color: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --view-short-promotion-hint-background: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --page-game--select-button-active-color: #FB196A;
  --page-profile--button-icon-color: #FB196A;
  --component-navigation--active-color: var(--button-primary-background);
  --component-area-title--dec-color: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);
  --component-tabs--active-color: linear-gradient(180deg, #FB196A 0%, #FF7BAA 0.01%, #FB196A 32.29%, #FB196A 69.27%, #D51459 100%);

  --page-profile--item-background: #F9F9F9;
  --page-profile--button-icon-color: #73199D;
  --page-profile--button-color: #000000;

  --page-share-background: linear-gradient(180deg, #8A1ABE 0%, #460A63 100%);
  --page-share-link-color: #FAD12B;

  --page-business-relationship--item-background: #F9F9F9;
  --page-business-relationship--item-text: #000000;
}